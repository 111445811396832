import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

// Assets
import downloadIcon from "@images/ih/shared/download-icon-white.svg"
import linkBgIh from "@images/ih/shared/link-ih-flat.svg"

const DownloadCalloutIh = ({ download, i }) => {
  return (
    <div
      css={[
        tw`bg-white rounded-b-[30px] rounded-tr-[60px] relative border border-coral-red w-full transition-main`,
      ]}
      key={i}
    >
      {/*link or download*/}
      <a
        css={[
          tw`pt-7 h-full flex flex-col justify-between`,
          tw`focus-visible:(outline-dusty-gray-1 outline-dashed outline-offset-8 outline-[1.5px])`,
        ]}
        href={download.pdf}
        target="_blank"
        rel="noreferrer"
      >
        <div tw="px-6">
          <h3 tw="mb-4 text-3xl leading-tight xl:(mb-4.5 text-[24px])">
            {download.title}
          </h3>{" "}
          <p css={[tw`font-light text-base`]}>{download.description}</p>
        </div>
        <div tw="flex items-center justify-end relative top-2.5 ml-auto min-h-[70px] pr-4 -mr-1">
          <span tw="relative z-10 text-white text-base font-bold flex items-center justify-center text-sm">
            Download{" "}
            <img
              src={downloadIcon}
              width="18px"
              height="18px"
              tw="ml-2.5"
              alt="download icon"
            />
          </span>
        </div>
        <picture tw="w-[177px] absolute right-[-1px] bottom-[-1px] z-0">
          <img src={linkBgIh} alt="" />
        </picture>
      </a>
    </div>
  )
}

export default DownloadCalloutIh

DownloadCalloutIh.propTypes = {
  downloads: PropTypes.array.isRequired,
}
